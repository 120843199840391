:root {
	/* Colors */
	--iy-primary: #f23d00;
	--default-text-color: #5f5f66;
	--info-color: #4d90fe;
	--success-color: #8c0;
	--warning-color: #ff7200;
	--error-color: #f00;
	--background-color: #f7f7f7;

	/* Fonts variables */
	--default-family-font: 'Titillium Web', sans-serif;
}

@font-face {
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 400;
	src: url('/fonts/titillium-web/titillium-web-v17-latin_latin-ext-regular.woff2')
		format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Titillium Web';
	font-style: normal;
	font-weight: 600;
	src: url('/fonts/titillium-web/titillium-web-v17-latin_latin-ext-600.woff2')
		format('woff2');
	font-display: swap;
}

body {
	position: relative;
	min-height: 100%;
	border-top: 4px solid #8590a6;
	font-family: var(--default-family-font);
	background-color: var(--background-color);
	color: var(--default-text-color);
	line-height: 1.5;
	padding: 30px 0;

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: -100px;
		left: 0;
		padding: 100px 0;
		width: 100%;
		height: 70%;
		background: #e6e9f0;
		z-index: -5;
		transform: skewY(-5deg);
	}

	@media all and (min-width: 425px) {
		padding: 100px 0;
	}
}

a {
	color: var(--iy-primary);

	&:hover,
	&:focus {
		color: var(--iy-primary);
	}
}

/**
 * Helpers
 */

.text-error {
	color: var(--error-color);
}

/**
 * Alert
 */
.alert {
	margin-top: 0;
	margin-bottom: 20px;
	border: none;
	border-left: 10px solid #333;
	background-color: #fff;
	color: #333;
	width: 100%;
	padding: 20px;
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
	text-align: left;
	border-radius: 0;

	.close {
		display: none;
	}

	&-info {
		border-left-color: var(--info-color);
		color: var(--info-color);
	}

	&-success {
		border-left-color: var(--success-color);
		color: var(--success-color);
	}

	&-warning {
		border-left-color: var(--warning-color);
		color: var(--warning-color);
	}

	&-danger {
		border-left-color: var(--error-color);
		color: var(--error-color);
	}
}

/**
 * Login
 */

.login-container {
	margin: 0 auto;
	padding: 0 15px;

	@media all and (min-width: 425px) {
		max-width: 385px;
	}
}

.login-header {
	text-align: center;
	margin-bottom: 30px;
}

.login-logo {
	width: 100%;
	max-width: 150px;
	vertical-align: initial;
}

.login-form {
	padding: 30px 20px;
	background-color: #fff;
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);

	p {
		margin-bottom: 10px;
	}
}

.login-footer {
	margin-top: 20px;
}

/**
 * Form
 */

.form-wrapper {
	margin-bottom: 30px;

	> .form-group {
		margin-bottom: 0;
	}
}

.form-group {
	margin-bottom: 30px;
	position: relative;
	padding: 0;

	&::after,
	&::before {
		content: '';
		width: 100%;
		height: 2px;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: #e7e7e7;
		z-index: 9;
	}

	&::after {
		background-color: var(--default-text-color);
		transform: scaleX(0);
		transition: transform 0.2s ease-in;
	}

	@keyframes autofill {
		to {
			color: var(--default-text-color);
			background: #fff;
		}
	}

	&.filled {
		label {
			transform: translateY(0);
		}
	}

	&.focused {
		label {
			transform: translateY(0);
			color: var(--default-text-color);
		}

		&.required > label::after {
			color: var(--default-text-color);
		}

		&::after {
			transform: scaleX(1);
		}
	}
}

.control-label {
	font-weight: normal;
	font-size: 16px;
	display: block;
	margin-bottom: 10px;
	position: relative;
	padding: 0;
	line-height: 16px;
	color: #bfbfbf;
	transform: translateY(30px);
	transform-origin: 0 50%;
	transition:
		transform 0.2s ease-in,
		padding 0.2s ease-in;
	text-align: left;
}

.form-control {
	font-size: 18px;
	display: block;
	position: relative;
	width: 100%;
	padding: 10px 0;
	border: none;
	line-height: 1.3333;
	height: 46px;
	background-color: transparent;
	appearance: none;
	outline: none;
	text-align: left;
	box-shadow: none;

	&:focus {
		border: none;
		box-shadow: none;
	}
}

.required > .control-label::after {
	content: ' *';
}

.form-error {
	&.form-group {
		&::after,
		&::before {
			background-color: var(--error-color);
		}
	}
}

/**
 * Button
 */

.btn {
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333;
	border-radius: 6px;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.btn-loading::before {
	content: '';
	display: inline-flex;
	border-radius: 20px;
	width: 20px;
	height: 20px;
	margin-right: 10px;
	border: 2px solid;
	border-top-color: #fff;
	animation: spin 1s linear infinite;
}
